// src/components/Footer.js
import React from 'react';
import './Footer.css'; 
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="https://bighitterss.io/assets/img/resources/footer_logo.png" alt="" />
          {/* <h2>BigHitterss</h2> */}
          {/* <p>Transforming Vision into Reality</p> */}
        </div>
        <p className="footer-description">
          From a teenager to an elderly person, every person thinks to have a safe
          and secure future life. Big Hitterss is being launched as a DE-fi coin
          based on Binance Smart Chain and with the aim of being the top earning
          and investing platform for each and every individual.
        </p>
        <p className="footer-email">
          Email Address: <a href="mailto:info@bighitterss.io">info@bighitterss.io</a>
        </p>
        <div className="footer-social-icons">
  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="social-icon">
    <FaFacebookF />
  </a>
  <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="social-icon">
    <FaTwitter />
  </a>
  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="social-icon">
    <GrInstagram />
  </a>
  <a href="https://www.linkedin.com/in/" target="_blank" rel="noopener noreferrer" className="social-icon">
    <FaLinkedin />
  </a>
</div>
<div class="footer-line"></div>

        <p className="footer-copy">
  <span className="white-text">© All Rights Reserved By     </span> 
  <a href="https://test.bighitterss.io/" target="_blank" rel="noopener noreferrer" className="highlight-same-color">
    BigHitters | Transforming Vision into Reality
  </a>
</p>

      </div>
    </footer>
  );
};

export default Footer;
