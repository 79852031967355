import React, { useContext, useEffect, useState } from 'react';
import './BuyBHTS.css'; // Ensure you have the correct path to your CSS file
import { WalletContext } from '../context/WalletContext';
import axios from 'axios';
import Web3 from 'web3';

const BuyBHTS = () => {
  const { account } = useContext(WalletContext); // Get account from WalletContext
  const [bhtsBalance, setBhtsBalance] = useState('');
  const [livePrice, setLivePrice] = useState(''); // State for storing live price
  const [usdtAmount, setUsdtAmount] = useState(''); // State for USDT input
  const [bhtsAmount, setBhtsAmount] = useState(''); // State for BHTS amount
 // State for storing popup messages
  const [loading, setLoading] = useState(false);
const [transactionInProgress, setTransactionInProgress] = useState(false);
const [apiTransactionComplete, setApiTransactionComplete] = useState(false);
const [popupMessage, setPopupMessage] = useState('');
// State for managing loader visibility

  const tokenContractAddress = "0x55d398326f99059ff775485246999027b3197955"; // BHTS contract address on BSC
  const ownerAddress = "0xc66223405906FC203D2a56E3939b201696f577Ef"; // Replace with the owner's address
  const bscChainId = '0x38'; // BSC Mainnet Chain ID in hex (56 in decimal)
  const bscRpcUrl = 'https://bsc-dataseed.binance.org/'; // BSC Mainnet RPC URL

  useEffect(() => {
    const fetchLivePrice = async () => {
      try {
        const response = await axios.post('https://test.bighitterss.io/ProcessAPIwithK.aspx', {
          reqtype: 'livepriceapi',
        });
        console.log('Live Price API Response:', response.data); // Debugging line
        setLivePrice(response.data.rate); // Update state with the live price
      } catch (error) {
        console.error('Error fetching live price:', error.message);
      }
    };

    fetchLivePrice(); // Fetch live price immediately when component mounts
  }, []);

  useEffect(() => {
    const fetchBalances = async () => {
      if (account) {
        console.log('Account:', account); // Debugging: Verify account is set
        try {
          const response = await axios.post('https://test.bighitterss.io/ProcessAPIwithK.aspx', {
            reqtype: 'balanceapi',
            walletaddress: account,
            tokencontractaddress: tokenContractAddress,
          });
          console.log('API Response:', response.data); // Debugging line
          const balance = response.data.Balance;
          setBhtsBalance(balance !== undefined ? balance.toString() : '0'); // Ensure balance is a string
        } catch (error) {
          console.error('Error fetching balance:', error.message);
          setBhtsBalance('0'); // Fallback in case of error
        }
      }
    };

    fetchBalances(); // Fetch balance immediately when account changes
  }, [account]);

  // Calculate USDT amount based on entered BHTS amount
  useEffect(() => {
    if (bhtsAmount && livePrice) {
      const calculatedUsdtAmount = (parseFloat(bhtsAmount) / parseFloat(livePrice)).toFixed(4);
      setUsdtAmount(calculatedUsdtAmount);
    } else {
      setUsdtAmount('');
    }
  }, [bhtsAmount, livePrice]);

  // Handle input change for BHTS amount
  const handleBhtsChange = (e) => {
    const inputValue = e.target.value;
    // Ensure the input does not exceed the user's BHTS balance
    if (parseFloat(inputValue) > parseFloat(bhtsBalance)) {
      alert('Entered amount exceeds your USDT balance');
    } else {
      setBhtsAmount(inputValue);
    }
  };

  // Ensure the user is connected to Binance Smart Chain
  const ensureBscNetwork = async (web3) => {
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId !== parseInt(bscChainId, 16)) { // Convert hex chain ID to decimal
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: bscChainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          // The BSC network is not added to MetaMask, so we try to add it
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: bscChainId,
                  chainName: 'Binance Smart Chain Mainnet',
                  rpcUrls: [bscRpcUrl],
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://bscscan.com'],
                },
              ],
            });
          } catch (addError) {
            console.error('Failed to add BSC network to MetaMask:', addError);
            throw addError;
          }
        } else {
          console.error('Failed to switch to BSC network:', switchError);
          throw switchError;
        }
      }
    }
  };

  // Handle the "Sell BHTS Token" button click
  const handleSellBhts = async () => {
    if (parseFloat(bhtsAmount) <= 0) {
      setPopupMessage('Please enter a valid amount to sell.');
      return;
    }
  
    if (parseFloat(bhtsAmount) > parseFloat(bhtsBalance)) {
      setPopupMessage('Entered amount exceeds your USDT balance.');
      return;
    }
  
    if (window.ethereum) {
      setLoading(true); // Show loader
      setTransactionInProgress(true); // Mark transaction as in progress
      setApiTransactionComplete(false); // Initialize API transaction status
  
      const web3 = new Web3(window.ethereum);
      try {
        // Ensure the user is on the Binance Smart Chain
        await ensureBscNetwork(web3);
  
        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        const accounts = await web3.eth.getAccounts();
        const sender = accounts[0];
  
        // Convert BHTS amount to Wei (or appropriate unit)
        const bhtsAmountInWei = web3.utils.toWei(bhtsAmount, 'ether'); // Ensure correct unit
  
        // Send BHTS tokens via MetaMask
        const tx = {
          from: sender,
          to: tokenContractAddress, // BHTS token contract address on BSC
          data: web3.eth.abi.encodeFunctionCall({
            name: 'transfer',
            type: 'function',
            inputs: [
              { type: 'address', name: 'to' },
              { type: 'uint256', name: 'value' }
            ]
          }, [ownerAddress, bhtsAmountInWei]), // To owner
          gas: '50000', // Set a reasonable gas limit
        };
  
        // Send transaction
        const txHash = await web3.eth.sendTransaction(tx);
        
        // Wait for transaction confirmation
        const txReceipt = await web3.eth.getTransactionReceipt(txHash.transactionHash);
        if (txReceipt.status) {
          // Call the API to process the transaction
          const payoutResponse = await axios.post('https://test.bighitterss.io/ProcessAPIwithK.aspx', {
            reqtype: 'apisinglepayout',
            to: sender,
            amount: usdtAmount,
          });
  
          if (payoutResponse.data.Status) {
            setApiTransactionComplete(true); // Mark API transaction as complete
            setPopupMessage('Amount sent successfully');
          } else {
            setPopupMessage('Transaction failed');
          }
        } else {
          setPopupMessage('Transaction failed');
        }
      } catch (error) {
        console.error('Error during transaction:', error.message);
        setPopupMessage('An error occurred during the transaction');
      } finally {
        setLoading(false); // Hide loader
        setTransactionInProgress(false); // Mark transaction as not in progress
      }
    } else {
      setPopupMessage('MetaMask not detected');
    }
  };
  

  return (
    <div className='row'>
      <div className='col-lg-4 col-sm-6 offset-lg-4 offset-sm-3'>
        <div className="gradient">
          <div className="card p-4 rounded-5 footer-wrap border-golden pt-5 pb-5 w-100 mb-0">
            <div className="bg-light mb-4">
              <div className="p-2 header-color">Buy BHTS</div>
            </div>
            <form>
              <div className="d-flex justify-content-between">
                <div className="coin-amount">
                  <b>USDT :{bhtsBalance}</b> 
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Live Price</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input type="text" className="form-control" placeholder="1.1" value={livePrice} readOnly/>
                  <button type="button" className="btn btn-style-1 rounded-0">
                    USDT/BHTS
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Enter USDT Token</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={0.0}
                    value={bhtsAmount}
                    onChange={handleBhtsChange}
                    style={{ width: "150px !important" }}
                  />
                   <button type="button" className="btn btn-style-1 rounded-0">
                    USDT
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">BHTS</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0"
                    value={usdtAmount} readOnly
                    style={{ width: "150px !important" }}
                  />
                   <button type="button" className="btn btn-style-1 rounded-0">
                    BHTS
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-style-2" onClick={handleSellBhts}>
                  Buy BHTS Token
                </button>
              </div>
            </form>
            {loading && (
              <div className="loader-overlay">
                <div className="spinner"></div>
                <p>Processing...</p>
              </div>
            )}
            {popupMessage && (
              <div className="popup-message">{popupMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
};



export default BuyBHTS;