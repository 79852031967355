import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { WalletContext } from '../context/WalletContext';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { account } = useContext(WalletContext); // Access the connected account from WalletContext

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMiningZoneClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src="https://bighitterss.io/assets/img/resources/footer_logo.png" alt="BigHitterss logo" width={"180"} />
        </a>
        <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavId">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="dropdownId"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Trade +
              </a>
              <div className="dropdown-menu" aria-labelledby="dropdownId">
                <Link className="dropdown-item" to="/connect-wallet">
                {account ? 'Connected' : 'Connect Wallet'}
                </Link>
                <Link className="dropdown-item" to="/buy-bhts">
                  Buy BHTS
                </Link>
                <Link className="dropdown-item" to="/sell-bhts">
                  Sell BHTS
                </Link>
              </div>
            </li>
            <li className="nav-item"><a className="nav-link" href="https://utility.clickcart.live/" target="_blank" rel="noopener noreferrer">Utility</a></li>
            <li className="nav-item"><a className="nav-link" href="https://food.clickcart.live/" target="_blank" rel="noopener noreferrer">Food</a></li>
            <li className="nav-item"><a className="nav-link" href="https://movie.clickcart.live/" target="_blank" rel="noopener noreferrer">Movie</a></li>
            <li className="nav-item"><a className="nav-link" href="https://clickcart.live/" target="_blank" rel="noopener noreferrer">Shopping</a></li>
            <li className="nav-item"><a className="nav-link" href="https://holiday.clickcart.live/" target="_blank" rel="noopener noreferrer">Holiday</a></li>
            <li className="nav-item"><a className="nav-link" href="https://gv.clickcart.live/" target="_blank" rel="noopener noreferrer">Gift Voucher</a></li>
            <li className="nav-item">
              <a className="btn btn-outline-success mining-zone" href="https://test.bighitterss.io/" rel="noopener noreferrer" onClick={handleMiningZoneClick}>Mining Zone</a>
            </li>
            <li className="nav-item">
              <a className="btn btn-outline-success white-paper" href="https://bighitterss.io/BIG%20HITTER%20WHITE%20PAPER.pdf" target="_blank" rel="noopener noreferrer">White Paper</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};



  // return (
  //   <header className="header">
  //     <div className="logo">
  //       <img src="https://bighitterss.io/assets/img/resources/footer_logo.png" alt="BigHitterss logo" />
  //     </div>
  //     <nav className="nav">
  //       <ul>
  //         <li className="dropdown">
  //           <a href="#" onClick={toggleDropdown} className="dropbtn">
  //             Trade 
  //           </a>
  //           {dropdownOpen && (
  //             <div className="dropdown-content">
  //               <Link to="/connect-wallet">{account ? 'Connected' : 'Connect Wallet'}</Link>
  //               <Link to="/Buy-bhts">Buy BHTS</Link>
  //               <Link to="/Sell-bhts">Sell BHTS</Link>
  //             </div>
  //           )}
  //         </li>
  //         <li><a href="https://utility.clickcart.live/" target="_blank" rel="noopener noreferrer">Utility</a></li>
  //         <li><a href="https://food.clickcart.live/" target="_blank" rel="noopener noreferrer">Food</a></li>
  //         <li><a href="https://movie.clickcart.live/" target="_blank" rel="noopener noreferrer">Movie</a></li>
  //         <li><a href="https://clickcart.live/" target="_blank" rel="noopener noreferrer">Shopping</a></li>
  //         <li><a href="https://holiday.clickcart.live/" target="_blank" rel="noopener noreferrer">Holiday</a></li>
  //         <li><a href="https://gv.clickcart.live/" target="_blank" rel="noopener noreferrer">Gift Voucher</a></li>
  //         <li><a href="https://test.bighitterss.io/" rel="noopener noreferrer" className="mining-zone" onClick={handleMiningZoneClick}>Mining Zone</a></li>
  //         <li><a href="https://bighitterss.io/BIG%20HITTER%20WHITE%20PAPER.pdf" target="_blank" rel="noopener noreferrer" className="white-paper">White Paper</a></li>
  //       </ul>
  //     </nav>
  //   </header>
  // );


export default Header;
