import React, { useState, useEffect } from 'react';
import './Slider.css';

const images = [
  'https://bighitterss.io/assets/img/slider/track-2.jpg',
  'https://bighitterss.io/assets/img/slider/track-1.jpg'
];

const Slider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Match with the total duration of the slide animation (6s)

    return () => clearInterval(interval);
  }, []);

  return (

    <div
      className="container"
    >
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner" role="listbox">
    <div className="carousel-item active rounded-4">
      <img
        src="https://bighitterss.io/assets/img/slider/track-2.jpg"
        className="w-100 d-block rounded-4"
        alt="First slide"
      />
    </div>
    <div className="carousel-item rounded-4">
      <img
        src="https://bighitterss.io/assets/img/slider/track-1.jpg"
        className="w-100 d-block rounded-4"
        alt="Second slide"
      />
    </div>
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselId"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselId"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

    </div>



    // <div className="slider">
    //   <img
    //     src={images[currentImageIndex]}
    //     alt="Crypto"
    //     className="slider-image"

    //     key={currentImageIndex}
    //   />
    // </div>
  );
};

export default Slider;
